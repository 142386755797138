import {
  ButtonComponent,
  DropDownComponent,
  DropDownComponentEntry,
  InputComponent,
  ListComponent,
  ListComponentEntry,
  PopUpComponent,
  SortableTableComponent,
  SwitchComponent,
} from "agrichema-component-library";

import AddIcon from "@material-ui/icons/Add";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import RemoveIcon from "@material-ui/icons/Remove";
import equal from "fast-deep-equal";
import {
  FC,
  FormEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useSWR from "swr";

import { useTranslation } from "react-i18next";
import { UserContext } from "../../pages/App";
import { useAxios } from "../../utils/AxiosUtil";
import {
  Bottle,
  DueDateType,
  Festigkeit,
  FilterBottle,
  HistoryShotObject,
  Nennweite,
  generateEmptyFilterBottle,
} from "../../utils/bottle/Bottle.types";
import { Customer } from "../../utils/customer/Customer.types";

import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {
  AreaBreak,
  AreaConfiguration,
  FactoryArea,
} from "../../utils/area/Area.types";
import {
  downloadMultipleDocuments,
  loadSingleDocument,
  uploadNewDocument,
} from "../../utils/document/Document.utils";
import {
  Page as NavigationPage,
  useNavigation,
} from "../../utils/hooks/useNavigation";
import {
  FilterBlower,
  ProductCheck,
  ProductCheckStatus,
  ProductLocation,
  ProductType,
  ShockBlower,
  createValivProductCheck,
  generateEmptyFilterBlower,
} from "../../utils/products/Product.types";
import {
  checkIfProductIsValid,
  createLocalShockBlower,
  createManyNewProducts,
  createNewProduct,
  deleteProductOnBackend,
  loadAllProductBreaks,
  loadAllProductDocuments,
  loadShockBlowerById,
  updateProduct,
} from "../../utils/products/ProductUtils";
import { UserRole } from "../../utils/user/User.types";
import {
  KolbensteuerungType,
  SpannungType,
  ValveConfiguration,
  ValveType,
} from "../../utils/valve/Valve.types";
import { loadAllValveConfig } from "../../utils/valve/ValveUtils";

import {
  NotificationType,
  generateNotification,
  getDateString,
  returnDateIfValid,
} from "../../utils/CommonUtils";
import {
  deleteAreaBreakOnBackend,
  loadAllFactoryAreasForCustomer,
} from "../../utils/area/Area.axios";
import {
  convertHourToMinuteAndViceVersa,
  createEmptyAreaConfiguration,
  getAppropriateAreaTable,
} from "../../utils/area/Area.util";
import { loadAllCustomer } from "../../utils/customer/Customer.axios";
import { createCustomerLocationDropDownEntries } from "../../utils/customer/Customer.util";
import {
  DocumentEntry,
  DocumentInformationType,
  DocumentType,
  generateNewDocumentEntry,
} from "../../utils/document/Document.types";
import AreaBreakForm from "../areaSubComponents/AreaBreakForm";
import FilterBottleForm from "../bottleSubcomponents/FilterBottle";
import CompletedCheck from "./CompletedCheck";
import FilterBlowerForm from "./FilterBlower";

export interface ProductFormProps {
  closeFunction(): void;
  productToEdit?: ShockBlower;
  isSpro?: boolean;
  factoryAreas: FactoryArea[];
}

const ProductForm: FC<ProductFormProps> = ({
  closeFunction,
  productToEdit,
  isSpro = false,
  factoryAreas,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [saveButtonIsLoading, toggleSaveButtonLoading] =
    useState<boolean>(false);
  const [deleteButtonIsLoading, toggleDeleteButtonLoading] =
    useState<boolean>(false);
  const { user } = useContext(UserContext);

  const [product, setProduct] = useState<ShockBlower>(
    productToEdit || createLocalShockBlower(user!.id!, isSpro)
  );
  const [singleBottleConfig, setSingleBottleConfig] =
    useState<AreaConfiguration>();

  const [initialBottleConfig, setInitalBottleConfig] = useState<
    Partial<{
      singleAreaConfig: AreaConfiguration;
      factoryAreaId: string;
    }>
  >();

  const [loadedFilteredBottles, setLoadedFilteredBottles] = useState<Bottle[]>(
    []
  );
  const [loadedSpros, setLoadedSpros] = useState<ShockBlower[]>([]);
  const [loadedCustomer, setLoadedCustomer] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [showSproSelection, toggleSproSelection] = useState<boolean>(false);
  const [showSproSwitch, toggleSproSwitchVisible] = useState<boolean>(false);
  const [popUpIsOpen, setPopUpIsOpen] = useState<boolean>(false);
  const [popUpListEntries, setPopUpListEntries] =
    useState<ListComponentEntry[]>();
  const [valveConfigurations, setValveConfiguration] = useState<
    ValveConfiguration[]
  >([]);

  const [showForm, toggleShowForm] = useState<boolean>(false);

  const [loadedFactoryAreas, setLoadedFactoryAreas] =
    useState<FactoryArea[]>(factoryAreas);

  const [showDocPopup, toggleDocPopup] = useState<boolean>(false);
  const [loadedDocument, setLoadedDocument] = useState<Blob>();
  const [deletePopupIsOpen, toggleDeletePopup] = useState<boolean>(false);

  const [breakToEdit, setBreakToEdit] = useState<AreaBreak>();
  const [selectedBottleEntries, setSelectedBottleEntries] = useState<Bottle[]>(
    []
  );

  const [isBottleSelection, setIsBottleSelection] = useState<boolean>(false);
  const [filterBottle, setFilterBottle] = useState<FilterBottle>(
    generateEmptyFilterBottle()
  );
  const [filterBlower, setFilterBlower] = useState<FilterBlower>(
    generateEmptyFilterBlower()
  );
  const [historyAvailable, toggleHistory] = useState<boolean>(true);
  const [createBatchMode, toggleBatchCreation] = useState<boolean>(false);

  const [includePastBreaks, togglePastBreaks] = useState<boolean>(false);
  const [includeArchivedDocs, toggleArchivedDocs] = useState<boolean>(false);

  const [newInnereDocumentEntry, setNewInnereDocumentEntry] =
    useState<DocumentEntry>(
      generateNewDocumentEntry(user?.id!, DocumentType.RETURNING_INNER, [
        product.bottle?.serialNumber ?? "",
      ])
    );

  const [innerCheckFile, setInnerCheckFile] = useState<File>();
  const [newFestigkeitDocumentEntry, setNewFestigkeitDocumentEntry] =
    useState<DocumentEntry>(
      generateNewDocumentEntry(user?.id!, DocumentType.RETURNING_FESTIGKEIT, [
        product.bottle?.serialNumber ?? "",
      ])
    );
  const [festigkeitCheckFile, setFestigkeitCheckFile] = useState<File>();

  const [productCheckValidity, setProductCheckValidity] =
    useState<ProductCheckStatus>(createValivProductCheck());

  const isCustomer = useMemo(() => user?.role === UserRole.CUSTOMER, [user]);

  /**
   * On customer selection fetches the available {@link FactoryArea}s
   */
  useEffect(() => {
    if (!axios || !user?.id || !selectedCustomer || !selectedCustomer.id)
      return;

    loadAllFactoryAreasForCustomer(
      selectedCustomer.id,
      [product.customerLocation],
      axios
    ).then((areas) => setLoadedFactoryAreas(areas));
  }, [selectedCustomer, user, axios, product.customerLocation]);

  /**
   * On change of filterBottle render filter the popuplistentries accordingly
   */
  useEffect(() => {
    if (!isBottleSelection || !loadedFilteredBottles) return;
    if (showSproSelection) {
      fillListWithSpro();
    } else fillListWithBlancoBottles();
    //eslint-disable-next-line
  }, [loadedFilteredBottles]);

  /**
   * On change of selected bottle for product, update document entries
   */
  useEffect(() => {
    if (!product?.bottle?.serialNumber || user?.role !== UserRole.SUPER_ADMIN)
      return;
    setNewInnereDocumentEntry((oldEntry) => ({
      ...oldEntry,
      bottleIds: [product.bottle?.serialNumber ?? ""],
    }));

    setNewFestigkeitDocumentEntry((oldEntry) => ({
      ...oldEntry,
      bottleIds: [product.bottle?.serialNumber ?? ""],
    }));
  }, [product.bottle?.serialNumber, user?.role]);

  const loadedBreaks = useSWR(
    !!axios && !!product.id
      ? includePastBreaks
        ? "breaks/past"
        : "breaks"
      : null,
    () => loadAllProductBreaks(axios!, product.id!, includePastBreaks),
    {
      fallbackData: [],
    }
  );

  const loadedDocuments = useSWR(
    !!axios && !!product.id
      ? includeArchivedDocs
        ? "documents/archived"
        : "documents"
      : null,
    () => loadAllProductDocuments(axios!, product.id!, includeArchivedDocs),
    {
      fallbackData: [],
    }
  );

  // load all related documents in case the opened product is a shockblower
  useEffect(() => {
    if (!product.id || !axios || product.type === ProductType.SPRO) return;
    setInitalBottleConfig({
      singleAreaConfig: product.bottle?.singleAreaBottleConfiguration,
      factoryAreaId: product.bottle?.factoryAreaId,
    });
  }, [product.id, product.type, product.bottle, axios]);

  /**
   * On product update, set single bottle config
   */
  useEffect(() => {
    if (!product || !product.bottle) return;
    setSingleBottleConfig(product.bottle.singleAreaBottleConfiguration);
  }, [product]);

  /**
   * Checks whether empty area configuration needs to be generated
   */
  useEffect(() => {
    if (product.bottle?.singleAreaBottleConfiguration) return;
    setProduct({
      ...product,
      bottle: {
        ...product.bottle!,
        singleAreaBottleConfiguration: createEmptyAreaConfiguration(),
      },
    });
    //eslint-disable-next-line
  }, [product.bottle]);

  const { onLocationChange } = useNavigation(NavigationPage.PRODUCT);

  const selectedDocumentsRef = useRef<string[]>();

  /**
   * Helper to submit the completed check document entries
   */
  const submitCompletedCheck = async (): Promise<void> => {
    !!innerCheckFile &&
      (await uploadNewDocument(newInnereDocumentEntry, innerCheckFile, axios));

    !!festigkeitCheckFile &&
      (await uploadNewDocument(
        newFestigkeitDocumentEntry,
        festigkeitCheckFile,
        axios
      ));
  };

  /**
   *  handles Submit Event, toggles button loading, disables it, when request is finished
   * @param event
   */
  const handleSubmit = async (
    event?: FormEvent,
    recalculate?: boolean
  ): Promise<void> => {
    event?.preventDefault();
    const productCheck: ProductCheckStatus = await checkIfProductIsValid(
      axios,
      product,
      selectedCustomer,
      isSpro,
      newFestigkeitDocumentEntry,
      festigkeitCheckFile,
      newInnereDocumentEntry,
      innerCheckFile
    );
    const hasError: boolean = Object.values(productCheck).some(
      (value) => value === false
    );

    setProductCheckValidity(productCheck);
    if (hasError) {
      return;
    }

    toggleSaveButtonLoading(true);
    let clonedProduct: ShockBlower = {
      ...product,
      activationDate: getProperActivationDate(),
    };
    event?.preventDefault();
    if (
      product.bottle?.factoryAreaId === null ||
      product.bottle?.factoryAreaId === undefined ||
      product.bottle?.factoryAreaId === "0"
    ) {
      if (
        (singleBottleConfig?.userConfiguringPerHour &&
          (singleBottleConfig?.shotsPerHour || 0) <= 0) ||
        (!singleBottleConfig?.userConfiguringPerHour &&
          (singleBottleConfig?.minutesPerShot || 0) <= 0)
      ) {
        clonedProduct = {
          ...clonedProduct,
          singleAreaBottleConfiguration: undefined,
        };
      }
    }

    const preparedHistoryShotList: HistoryShotObject[] = [
      ...(clonedProduct.bottle?.historyShotCountList
        ? clonedProduct.bottle?.historyShotCountList.filter(
            (history) => !Number.isNaN(+history.shotCount)
          )
        : []),
    ];

    //If either the configuration of the product has changed or if it has been assigned to a different
    //area, then push the current shot count of said bottle into the history shot list
    if (
      product.id &&
      (clonedProduct.bottle?.currentShotCount ?? 0) > 0 &&
      (!equal(
        clonedProduct.bottle?.singleAreaBottleConfiguration,
        initialBottleConfig?.singleAreaConfig
      ) ||
        !equal(
          clonedProduct.bottle?.factoryAreaId,
          initialBottleConfig?.factoryAreaId
        ))
    ) {
      preparedHistoryShotList.push({
        id: undefined!,
        shotCount: clonedProduct.bottle?.currentShotCount!,
        shotDate: new Date(),
        isSnapshot: true,
      });
    }

    if (product.id) {
      const updatedBlower = await updateProduct(
        {
          ...clonedProduct,
          bottle: {
            ...clonedProduct.bottle!,
            historyShotCountList: preparedHistoryShotList,
          },
        },
        !!recalculate,
        axios
      );

      await submitCompletedCheck();

      !!updatedBlower && setProduct(updatedBlower);
      toggleDeleteButtonLoading(false);
      toggleSaveButtonLoading(false);
      loadedDocuments.mutate();
    } else if (createBatchMode && selectedBottleEntries.length > 1) {
      createManyNewProducts(
        {
          ...clonedProduct,
          bottle: {
            ...clonedProduct.bottle!,
          },
        },
        selectedBottleEntries.flatMap((bottle) => bottle.id || ""),
        axios
      )
        .then((success) => {
          if (success) {
            closeFunction();
          }
          toggleDeleteButtonLoading(false);
          toggleSaveButtonLoading(false);
        })
        .catch(() => toggleDeleteButtonLoading(false));
    } else {
      const success: boolean = await createNewProduct(
        {
          ...clonedProduct,
          bottle: {
            ...clonedProduct.bottle!,
            historyShotCountList: preparedHistoryShotList,
          },
        },
        !!recalculate,
        axios
      );
      toggleDeleteButtonLoading(false);
      toggleSaveButtonLoading(false);
      if (!success) return;
      await submitCompletedCheck();
      closeFunction();
    }
  };

  /**
   *  handles Delete Event, toggles button loading, disables it, when request is finished
   * @param event
   */
  const handleDelete = async (): Promise<void> => {
    toggleSaveButtonLoading(true);
    deleteProductOnBackend(product.id!, axios)
      .then((success) => {
        if (success) {
          closeFunction();
        }
        toggleDeleteButtonLoading(false);
      })
      .catch(() => toggleDeleteButtonLoading(false));
  };

  /**
   * Internal helper to generate the list entries for the bottle popup in
   * case the SPRO switch is set to false
   */
  const fillListWithBlancoBottles = (): void => {
    setPopUpListEntries(
      loadedFilteredBottles.map((bottle) => ({
        id: bottle.id!,
        label: `${t("general.documentInformation.SERIAL_NUMBER")}: ${
          bottle.serialNumber
        } |
        ${t("Bottle.type")}: ${bottle.config?.type} |
        ${t("general.documentInformation.VOLUME")}: ${t(
          `Bottle.Config.volumes.${bottle.config?.volumen}`
        )} |
        ${t("general.documentInformation.NENNWEITE")}: ${t(
          `Bottle.Config.nennweiten.${bottle.config?.nennweite}`
        )} |
        ${t("general.documentInformation.FESTIGKEIT")}: ${t(
          `Bottle.Config.festigkeiten.${bottle.config?.festigkeit}`
        )}
        `,
        onClick: () => {
          setProduct({
            ...product,
            bottle: {
              ...bottle!,
              dueDates: new Map<DueDateType, Date>(
                Object.entries(bottle?.dueDates ?? {}) as [DueDateType, Date][]
              ),
              singleAreaBottleConfiguration:
                bottle.singleAreaBottleConfiguration &&
                bottle.config?.maxShots.pressureTen !== -1
                  ? bottle.singleAreaBottleConfiguration
                  : createEmptyAreaConfiguration(),
            },
            valve: undefined,
            valveType: undefined,
            nennweite: undefined,
            kolbensteuerung: undefined,
            spannung: undefined,
            availableDocuments: undefined,
          });
          if (!createBatchMode) {
            setPopUpIsOpen(false);
            setIsBottleSelection(false);
          }
        },
      }))
    );
  };

  /**
   * Helper to create appropriate dropdown entries for list of factory areas
   * @returns list of {@link DropDownComponentEntry}
   */
  const createFactoryAreaDropdown = (): DropDownComponentEntry[] => {
    const factoryAreaDropDownEntries: DropDownComponentEntry[] =
      loadedFactoryAreas.map((area) => ({
        label: area.title,
        value: area.id,
      }));

    return [
      { label: t("Bottle.Config.noArea"), value: "0", toTop: true },
      ...factoryAreaDropDownEntries,
    ];
  };

  /**
   * Internal helper to generate the bottle list entries for the popup in case
   * the SPRO switch is set to true
   *
   * @param spros The SPROs to display
   */
  const fillListWithSpro = (): void => {
    setPopUpListEntries(
      loadedSpros.map((spro) => ({
        id: spro.id!,
        label: `${t("Bottle.type")}: ${spro.bottle!.config?.type} |
      ${t("general.documentInformation.VOLUME")}: ${t(
          `Bottle.Config.volumes.${spro.bottle!.config?.volumen}`
        )} |
      ${t("general.documentInformation.NENNWEITE")}: ${t(
          `Bottle.Config.nennweiten.${spro.bottle!.config?.nennweite}`
        )} |
      ${t("general.documentInformation.FESTIGKEIT")}: ${t(
          `Bottle.Config.festigkeiten.${spro.bottle!.config?.festigkeit}`
        )}
      `,
        onClick: () => {
          setProduct({
            ...product,
            bottle: spro.bottle,
            valve: spro.valve,
            valveType: spro.valveType,
            nennweite: spro.nennweite,
            kolbensteuerung: spro.kolbensteuerung,
            spannung: undefined,
          });
          setPopUpIsOpen(false);
          toggleSproSelection(false);
        },
      }))
    );
  };

  /**
   *  helper function for opening the Pop Up, generates list, that needs to be shown
   * @param type
   */
  const openPopUp = (type: "bottle" | "customer"): void => {
    setPopUpIsOpen(true);
    if (!axios) {
      return;
    }
    if (type === "bottle") {
      toggleSproSwitchVisible(product.type === ProductType.SHOCK_BLOWER);
      fillListWithBlancoBottles();
      setIsBottleSelection(true);
      setSelectedBottleEntries([]);
      setProduct({ ...product, bottle: undefined });
    } else {
      setIsBottleSelection(false);
      toggleSproSwitchVisible(false);
      setPopUpListEntries(
        loadedCustomer.map((customer) => ({
          id: customer.id!,
          label: `${customer.company.name}`,
          onClick: () => {
            setProduct({ ...product, customerId: customer.id! });
            setSelectedCustomer(customer);
            setPopUpIsOpen(false);
          },
        }))
      );
    }
  };

  /**
   * Helper to create drop down entries for Pressure Range
   * @returns list of {@link DropDownComponentEntry}s
   */
  const createPressureRangeDropDownEntries = (): DropDownComponentEntry[] => {
    return Object.values(Festigkeit).map((entry) => ({
      label: t(`Area.pressureRange.${entry}`),
      value: entry,
    }));
  };

  /**
   * Helper to round to two round to two decimals
   * @param numb - the number
   * @returns - rounded number
   */
  const roundToTwoDecimals = (numb: number) => {
    return Math.round((numb + Number.EPSILON) * 100) / 100;
  };

  // fetch all user and bottles
  useEffect(() => {
    if (!axios) return;
    loadAllCustomer(axios).then(setLoadedCustomer);
  }, [axios]);

  // fetches all valveconfigs
  useEffect(() => {
    if (
      axios &&
      (product.type === ProductType.SHOCK_BLOWER ||
        product.type === ProductType.SPRO)
    ) {
      loadAllValveConfig(axios).then(setValveConfiguration);
    }
  }, [product.type, axios]);

  // if the product has an assigned customer it will be setted here for display reasons
  useEffect(() => {
    if (
      product.id &&
      product?.customerId &&
      !selectedCustomer &&
      loadedCustomer
    )
      setSelectedCustomer(
        loadedCustomer.find((customer) => customer.id === product.customerId)
      );
  }, [selectedCustomer, loadedCustomer, product.id, product.customerId]);

  // listens on the SPRO switch and updates the list entries accordingly
  useEffect(() => {
    if (
      !popUpIsOpen ||
      product.type !== ProductType.SHOCK_BLOWER ||
      !loadedSpros ||
      !isBottleSelection
    )
      return;
    if (showSproSelection) fillListWithSpro();
    else {
      fillListWithBlancoBottles();
    }
    //eslint-disable-next-line
  }, [showSproSelection, loadedSpros, popUpIsOpen, product.type]);

  /**
   * When bottle assigned to blower has own config,
   * take its respective shot configuration and coverts it to the counterpart value
   * shotsPerHour => minutesPerShot OR minutesPerShot => shotsPerHour
   */
  useEffect(() => {
    if (!product.bottle?.singleAreaBottleConfiguration) return;
    const userConfiguringPerHour: boolean =
      product.bottle?.singleAreaBottleConfiguration.userConfiguringPerHour;
    if (userConfiguringPerHour) {
      const minutesPerShot: number | undefined =
        product.bottle?.singleAreaBottleConfiguration.minutesPerShot;
      setProduct({
        ...product,
        bottle: {
          ...product.bottle!,
          singleAreaBottleConfiguration: {
            ...product.bottle!.singleAreaBottleConfiguration,
            shotsPerHour: convertHourToMinuteAndViceVersa(minutesPerShot || 0),
          },
        },
      });
    } else {
      const shotsPerHour: number | undefined =
        product.bottle?.singleAreaBottleConfiguration.shotsPerHour;

      setProduct({
        ...product,
        bottle: {
          ...product.bottle!,
          singleAreaBottleConfiguration: {
            ...product.bottle!.singleAreaBottleConfiguration,
            minutesPerShot: convertHourToMinuteAndViceVersa(shotsPerHour || 0),
          },
        },
      });
    }
    //eslint-disable-next-line
  }, [product.bottle?.singleAreaBottleConfiguration?.userConfiguringPerHour]);

  /**
   * Helper to generate a switch component in case the SPRO need to be selectable in
   * the popup header
   *
   * @returns The JSX Element to display in the header
   */
  const getPopupHeader = (): JSX.Element => {
    let headerElement = [];
    switch (product.type) {
      case ProductType.SHOCK_BLOWER:
      case ProductType.SPRO:
        if (showSproSwitch && !createBatchMode)
          headerElement.push(
            <SwitchComponent
              onChange={toggleSproSelection}
              value={showSproSelection}
              label={t("Product.toggleSpro")}
            />
          );
        if (isBottleSelection && !showSproSelection)
          headerElement.push(
            <div className="product-form--bottle-filter-components">
              <FilterBottleForm
                setFilterBottles={setLoadedFilteredBottles}
                filterBottle={filterBottle}
                setFilterBottle={setFilterBottle}
              />
            </div>
          );
        else if (isBottleSelection && showSproSelection)
          headerElement.push(
            <div className="product-form--bottle-filter-components">
              <FilterBlowerForm
                isSpro
                filterBlower={filterBlower}
                setFilterBlower={setFilterBlower}
                setLoadedFilteredProducts={setLoadedSpros}
              />
            </div>
          );
        return <>{headerElement}</>;
      default:
        return <></>;
    }
  };

  /**
   * Helper function to load the single document from the backend
   * and open up the PopUpComponent
   * @param documentEntryId the id of the document to be loaded
   */
  const openDocumentPreview = (documentEntryId: string): void => {
    loadSingleDocument(axios, documentEntryId).then((documentEntry) => {
      const documentBlob = new Blob([documentEntry], {
        type: "application/pdf",
      });
      setLoadedDocument(documentBlob);
      toggleDocPopup(true);
    });
  };

  /**
   * Helper to generate the document entries for this product
   *
   * @returns The generated document entries
   */
  const generateDocumentData = (): {
    documentType: string;
    pdfFile: JSX.Element;
  }[] => {
    return loadedDocuments.data.map((documentEntry) => ({
      id: documentEntry.id,
      documentType: t(`Document.types.${documentEntry.type}`),
      pdfFile: (
        <PictureAsPdfIcon
          className="clickable"
          onClick={(evt) => {
            evt.stopPropagation();
            openDocumentPreview(documentEntry.id!);
          }}
        />
      ),
      shotCount: documentEntry.documentInformation.get(
        DocumentInformationType.SHOT_COUNT
      ),
      checkDate: !!documentEntry.documentInformation.get(
        DocumentInformationType.CHECK_COMPLETED
      )
        ? getDateString(
            new Date(
              documentEntry.documentInformation.get(
                DocumentInformationType.CHECK_COMPLETED
              ) || ""
            )
          )
        : undefined,
    }));
  };

  /**
   *
   * @returns the correct activation date which in other cases is the 01-01 of given build year
   */
  const getProperActivationDate = (): Date => {
    if (product?.activationDate) {
      return new Date(product.activationDate);
    } else if (product?.bottle?.buildYear) {
      return new Date(`${product.bottle.buildYear}`);
    }
    return new Date();
  };

  /**
   * Checks if given activation  Date is before build year and if not sets it
   * @param newDate
   * @returns
   */
  const checkIfGivenDateIsBeforeActivationDate = (newDate: Date): void => {
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 2);
    if (isNaN(newDate.getTime())) return;

    setProduct((oldProduct) => {
      return {
        ...oldProduct,
        activationDate: newDate,
      };
    });
  };

  /**
   * Helper function to set the date of a new history shot object
   * @param clonedHistoryList - the list of history objects
   * @param historyShotIndex - current index for edited history shot
   * @param dateValue - the new passed date as string
   */
  const setShotCountDateForHistoryShotObject = (
    clonedHistoryList: HistoryShotObject[],
    historyShotIndex: number,
    dateValue: string
  ): void => {
    if (!dateValue || dateValue.length <= 0) return;
    const newDate: Date = new Date(Date.parse(dateValue + "T00:00:00.000Z"));
    clonedHistoryList[historyShotIndex].shotDate = newDate;

    setProduct((oldProduct) => {
      return {
        ...oldProduct!,
        bottle: {
          ...oldProduct.bottle!,
          historyShotCountList: clonedHistoryList,
        },
      };
    });
  };

  /**
   * Helper to handle deletion of {@link AreaBreak}
   */
  const handleBreakDeletion = (): void => {
    if (!breakToEdit) return;
    deleteAreaBreakOnBackend(breakToEdit.id, axios).then((success) => {
      if (success) {
        loadedBreaks.mutate();
        toggleShowForm(false);
        setBreakToEdit(undefined);
      }
    });
  };

  /**
   * Helper method to get correct value of input field which asks for either hour or minutes
   * @returns
   */
  const getCorrectValueForHourOrMinuteInput = (): string => {
    return product.bottle?.singleAreaBottleConfiguration!.userConfiguringPerHour
      ? roundToTwoDecimals(
          product.bottle?.singleAreaBottleConfiguration!.shotsPerHour || 0
        ).toString() || ""
      : roundToTwoDecimals(
          product.bottle?.singleAreaBottleConfiguration!.minutesPerShot || 0
        ).toString() || "";
  };
  /**
   * Helper method to handle the onChange of input field
   * @param newValue - the entered value
   */
  const onChangeHandlerForHourOrMinuteInput = (newValue: string): void => {
    product.bottle?.singleAreaBottleConfiguration!.userConfiguringPerHour
      ? setProduct({
          ...product,
          bottle: {
            ...product.bottle!,
            singleAreaBottleConfiguration: {
              ...product.bottle!.singleAreaBottleConfiguration!,
              shotsPerHour: parseFloat(newValue),
            },
          },
        })
      : setProduct({
          ...product,
          bottle: {
            ...product.bottle!,
            singleAreaBottleConfiguration: {
              ...product.bottle!.singleAreaBottleConfiguration!,
              minutesPerShot: parseFloat(newValue),
            },
          },
        });
  };

  /**
   * Handles the change of a shot count for a history shot object
   * @param newValue - the new value
   * @param clonedHistoryShotList - the cloned list of history shot objects
   * @param index - the index of the history shot object that changed
   */
  const handleHistoryShotCountChange = (
    newValue: string,
    clonedHistoryShotList: HistoryShotObject[],
    index: number
  ): void => {
    const clonedHistoryList = [...clonedHistoryShotList!];
    clonedHistoryList[index].shotCount = parseInt(newValue);
    setProduct({
      ...product,
      bottle: {
        ...product.bottle!,
        historyShotCountList: !Number.isNaN(parseInt(newValue))
          ? [...clonedHistoryList]
          : [...clonedHistoryShotList],
      },
    });
  };

  /**
   * Helper function to prepare the render of the history shot object
   * @returns - JSX.Element
   */
  const prepareHistoryShotForRender = (): JSX.Element[] => {
    let clonedHistoryShotList: HistoryShotObject[] = [
      ...(product.bottle?.historyShotCountList?.filter(
        (count) => !count.isSnapshot
      ) || []),
    ];

    let totalShotCount: number = product.bottle?.currentShotCount || 0;

    const historyElements: JSX.Element[] = clonedHistoryShotList.map(
      (historyShot, index) => {
        const shotCountDifferencePreviousElement: number =
          index > 0
            ? clonedHistoryShotList[index].shotCount -
              clonedHistoryShotList[index - 1].shotCount
            : 0;
        if (index === clonedHistoryShotList.length - 1)
          totalShotCount += historyShot.shotCount;
        return (
          <div className="inventory-page-history--container">
            {index > 0 && !Number.isNaN(shotCountDifferencePreviousElement) && (
              <p className="difference-text">
                {t("Product.gap")} {shotCountDifferencePreviousElement}
              </p>
            )}

            <InputComponent
              disabled={saveButtonIsLoading || !selectedCustomer || isCustomer}
              label={t("Product.historyShotCount")}
              onBlur={checkProductValidity}
              value={historyShot.shotCount.toString() || "0"}
              onChange={(newValue) => {
                handleHistoryShotCountChange(
                  newValue,
                  clonedHistoryShotList,
                  index
                );
              }}
              type="number"
            />
            <InputComponent
              disabled={saveButtonIsLoading || !selectedCustomer || isCustomer}
              onBlur={checkProductValidity}
              required={historyShot.shotCount > 0}
              label={t("Product.historyShotDate")}
              value={
                (historyShot.shotDate
                  ? new Date(historyShot.shotDate)
                  : new Date()
                )
                  .toISOString()
                  .split("T")[0]
              }
              onChange={(value) =>
                setShotCountDateForHistoryShotObject(
                  [...clonedHistoryShotList!],
                  index,
                  value
                )
              }
              type="date"
              minDate={
                index > 0
                  ? new Date(clonedHistoryShotList[index - 1].shotDate)
                      .toISOString()
                      .split("T")[0]
                  : ""
              }
              maxDate={new Date().toISOString().split("T")[0]}
            />
            {isCustomer || (
              <DeleteOutline
                onClick={() => {
                  const clonedHistoryList: HistoryShotObject[] = [
                    ...clonedHistoryShotList!,
                  ];
                  clonedHistoryList.splice(index, 1);
                  setProduct({
                    ...product,
                    bottle: {
                      ...product.bottle!,
                      historyShotCountList: [...clonedHistoryList],
                    },
                  });
                }}
              />
            )}
          </div>
        );
      }
    );
    historyElements.push(<div className="break-line" />);
    historyElements.push(
      <div className="inventory-page-history--container">
        <InputComponent
          disabled
          label={t("Product.historyShotCountTotal")}
          value={totalShotCount.toString() || "0"}
          onChange={() => {}}
          type="number"
        />
        <InputComponent
          disabled
          label={t("Product.today")}
          value={new Date().toISOString().split("T")[0]}
          onChange={(value) => {}}
          type="date"
        />

        <div style={{ width: "50px", height: "50px" }} />
      </div>
    );

    return historyElements;
  };

  /**
   * Helper to switch between batch mode and single mode
   */
  const switchBetweenBatchMode = (): void => {
    if (createBatchMode) {
      setProduct({
        ...product,
        bottle: undefined!,
      });
      setSelectedBottleEntries([]);
      toggleBatchCreation(false);
    } else toggleBatchCreation(true);
  };

  /**
   * Adds a new history shot count to the list
   */
  const addHistoryShotCount = (): void =>
    setProduct((oldProduct) => ({
      ...oldProduct,
      bottle: {
        ...product.bottle!,
        historyShotCountList: [
          ...(product.bottle?.historyShotCountList || []),
          {
            shotDate: new Date(),
            shotCount:
              product.bottle?.historyShotCountList?.[
                product.bottle?.historyShotCountList.length - 1
              ]?.shotCount ?? 0,
            id: undefined!,
            isSnapshot: false,
          },
        ],
      },
    }));

  /**
   * Get correct name for area break assignment
   * @param areaBreak  - the area break
   * @returns  - the displayed title for Area Assignment column
   */
  const getCorrectBreakAssignmentName = (areaBreak: AreaBreak): string => {
    return areaBreak.factoryAreaIds.length > 0
      ? loadedFactoryAreas.find(
          (area) => area.id === product.bottle?.factoryAreaId
        )?.title || ""
      : areaBreak.blowerId
      ? t("Product.breakForBlower")
      : t("Product.breakAllAreas");
  };

  /**
   * Helper to update partial product
   * @param partialProduct  - the partial product
   */
  const updatePartialProduct = (partialProduct: Partial<ShockBlower>): void => {
    setProduct((oldProduct) => ({
      ...oldProduct,
      ...partialProduct,
    }));
  };

  /**
   * Helper to check if product is valid
   */
  const checkProductValidity = async (): Promise<void> => {
    const productCheckStatus: ProductCheckStatus = await checkIfProductIsValid(
      axios,
      product,
      selectedCustomer,
      isSpro,
      newFestigkeitDocumentEntry,
      festigkeitCheckFile,
      newInnereDocumentEntry,
      innerCheckFile
    );
    setProductCheckValidity(productCheckStatus);
  };

  /**
   * If customer or selected bottle changes, trigger product check
   */
  useEffect(() => {
    if (!axios || (!product.bottle?.id && !selectedCustomer) || !!product.id)
      return;
    checkProductValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.bottle?.id, selectedCustomer, axios]);

  /**
   * Helper to prepare selected documents to be downloaded
   */
  const prepareForProductDocumentDownload = async (): Promise<void> => {
    if (
      !selectedDocumentsRef.current ||
      selectedDocumentsRef.current?.length === 0
    ) {
      generateNotification(
        t("Product.error.noDocumentSelected"),
        NotificationType.WARNING
      );
      return;
    }
    downloadMultipleDocuments(axios, selectedDocumentsRef.current ?? []);
    selectedDocumentsRef.current = [];
  };
  return (
    <>
      {showForm && product.id ? (
        <AreaBreakForm
          blowerId={product.id}
          customerId={product.customerId}
          breakForBottle
          factoryAreas={factoryAreas}
          breakToEdit={breakToEdit}
          handleDeletion={handleBreakDeletion}
          closeFunction={(areaBreak) => {
            if (areaBreak) {
              loadedBreaks.mutate();
              loadShockBlowerById(product.id!, axios).then(setProduct);
            }
            setBreakToEdit(undefined);
            toggleShowForm(false);
          }}
        />
      ) : (
        <div className="inventory-form">
          <h2>
            {!product.id
              ? t("Product.createProduct")
              : t("Product.updateProduct")}
          </h2>
          {product.id ? (
            <div className="add-break-button-wrapper">
              <ButtonComponent
                onClick={() => toggleShowForm(true)}
                title={t("Product.breakForProduct")}
                disabled={isCustomer}
              />
            </div>
          ) : (
            <SwitchComponent
              value={createBatchMode}
              onChange={() => switchBetweenBatchMode()}
              label={t(`Product.createProductBatch`)}
            />
          )}
          <form onSubmit={(evt) => handleSubmit(evt, false)}>
            <DropDownComponent
              entries={Object.values(ProductType).map((value) => ({
                label: t(`Product.${value}`),
                value: value,
              }))}
              label={t("Product.type")}
              onSelect={(selectedValue) =>
                setProduct({
                  ...product,
                  type: selectedValue as ProductType,
                })
              }
              selectedValue={product.type}
              disabled={!!product.id || createBatchMode || isCustomer}
            />
            <div className="break-line" />
            <div
              className={[
                "inventory-form--select-input",
                product.id ? "edit" : "view",
              ].join(" ")}
            >
              <InputComponent
                label={t("Product.placeholder.bottle")}
                value={
                  selectedBottleEntries.length > 1
                    ? `${selectedBottleEntries
                        .map((entry) => entry.serialNumber)
                        .join(", ")}`
                    : `${product.bottle?.serialNumber || ""}`
                }
                onChange={(value) =>
                  setProduct((oldProduct) => ({
                    ...oldProduct,
                    bottle: { ...oldProduct.bottle!, serialNumber: value },
                  }))
                }
                disabled={!!product.id || createBatchMode}
                onBlur={checkProductValidity}
                bottomErrorLabel={`${
                  !productCheckValidity?.SERIAL_NUMBER_REQUIRED
                    ? t("Product.error.SERIAL_NUMBER_REQUIRED")
                    : !productCheckValidity?.SERIAL_NUMBER_AVAILABLE
                    ? t("Product.error.SERIAL_NUMBER_AVAILABLE")
                    : !productCheckValidity?.SERIAL_NUMBER_EXIST
                    ? t("Product.error.SERIAL_NUMBER_EXIST")
                    : ""
                }`}
              />
              {!product.id && (
                <ButtonComponent
                  title={t("general.button.select")}
                  type="button"
                  onClick={() => openPopUp("bottle")}
                />
              )}
            </div>
            <div className="break-line" />
            <DropDownComponent
              entries={valveConfigurations
                // filter is needed so that the entries are unique
                .filter(
                  (valve, index, valveArray) =>
                    valveArray.findIndex(
                      (tempValve) => tempValve.valve === valve.valve
                    ) === index
                )
                .map((valveConfig) => ({
                  label: t(`Product.config.valve.${valveConfig.valve}`),
                  value: valveConfig.valve,
                }))}
              label={t(`general.documentInformation.HARDWARE_TYPE`)}
              onSelect={(newValue) =>
                setProduct({ ...product, valve: newValue })
              }
              selectedValue={product.valve || ""}
              emptyOptionEnabled
              placeholder={t(`general.noSelection`)}
              disabled={isCustomer}
            />
            <DropDownComponent
              entries={Object.keys(ValveType).map((valveType) => ({
                label: t(`Product.config.valveType.${valveType}`),
                value: valveType,
              }))}
              label={t(`general.documentInformation.VALVE_TECH`)}
              onSelect={(newValue) =>
                setProduct({ ...product, valveType: newValue })
              }
              selectedValue={product.valveType || ""}
              emptyOptionEnabled
              placeholder={t(`general.noSelection`)}
              disabled={isCustomer}
            />
            <DropDownComponent
              entries={Object.keys(Nennweite).map((nennweite) => ({
                label: t(`Bottle.Config.nennweiten.${nennweite}`),
                value: nennweite,
              }))}
              label={t(`general.documentInformation.NENNWEITE`)}
              onSelect={(newValue) =>
                setProduct({ ...product, nennweite: newValue })
              }
              selectedValue={product.nennweite || ""}
              emptyOptionEnabled
              placeholder={t(`general.noSelection`)}
              disabled={isCustomer}
            />
            <DropDownComponent
              entries={Object.keys(KolbensteuerungType).map(
                (kolbensteuerung) => ({
                  label: t(
                    `Product.config.kolbensteuerungType.${kolbensteuerung}`
                  ),
                  value: kolbensteuerung,
                })
              )}
              label={t(`general.documentInformation.KOLBENSTEUERUNG`)}
              onSelect={(newValue) =>
                setProduct({ ...product, kolbensteuerung: newValue })
              }
              selectedValue={product.kolbensteuerung || ""}
              emptyOptionEnabled
              placeholder={t(`general.noSelection`)}
              disabled={isCustomer}
            />
            {product.type !== ProductType.SPRO && (
              <>
                <DropDownComponent
                  entries={Object.keys(SpannungType).map((spannung) => ({
                    label: t(`Product.config.spannungType.${spannung}`),
                    value: spannung,
                  }))}
                  label={t(`general.documentInformation.SPANNUNG`)}
                  onSelect={(newValue) =>
                    setProduct({ ...product, spannung: newValue })
                  }
                  selectedValue={product.spannung || ""}
                  emptyOptionEnabled
                  placeholder={t(`general.noSelection`)}
                  disabled={isCustomer}
                />

                <div
                  className={[
                    "inventory-form--select-input",
                    product.id ? "edit" : "view",
                  ].join(" ")}
                >
                  <InputComponent
                    required
                    label={t("Product.placeholder.customer")}
                    value={selectedCustomer?.company.name || ""}
                    onChange={() => {}}
                    disabled
                    bottomErrorLabel={
                      !productCheckValidity.CUSTOMER_SELECTED
                        ? t("Product.error.CUSTOMER_SELECTED")
                        : ""
                    }
                  />
                  {!product.id && (
                    <ButtonComponent
                      title={t("general.button.select")}
                      type="button"
                      onClick={() => openPopUp("customer")}
                    />
                  )}
                </div>
                {!!selectedCustomer &&
                  selectedCustomer.company.locations.length > 0 && (
                    <DropDownComponent
                      required
                      entries={createCustomerLocationDropDownEntries(
                        selectedCustomer
                      )}
                      label={t(`general.documentInformation.LOCATION`)}
                      onSelect={(customerLocation) =>
                        setProduct({ ...product, customerLocation })
                      }
                      selectedValue={product.customerLocation}
                      disabled={!selectedCustomer || !!product.id}
                    />
                  )}
                <DropDownComponent
                  disabled={
                    (!!product.id &&
                      product.productLocation ===
                        ProductLocation.VERSCHROTTET) ||
                    isCustomer
                  }
                  entries={
                    Object.keys(ProductLocation).map((location) => ({
                      label: t(`Product.productLocation.${location}`),
                      value: location,
                    })) || []
                  }
                  label={t(`Product.productLocation.header`)}
                  onSelect={(newValue) =>
                    setProduct({
                      ...product,
                      productLocation: newValue as ProductLocation,
                    })
                  }
                  selectedValue={product.productLocation || ""}
                />
                <InputComponent
                  onChange={(newValue) => {
                    setProduct({ ...product, comNumber: newValue });
                  }}
                  onBlur={checkProductValidity}
                  value={
                    product.comNumber.charAt(0) === "V"
                      ? product.comNumber
                      : `${t("Product.comNumberPrefix")}${product.comNumber}`
                  }
                  label={t("Product.comNumber")}
                  bottomErrorLabel={
                    !productCheckValidity?.COM_NUMBER_VALID
                      ? t(`Product.error.${ProductCheck.COM_NUMBER_VALID}`)
                      : ""
                  }
                  disabled={isCustomer}
                />
              </>
            )}
            <div className="bottle-notes-container">
              <div className="button-wrapper">
                <p>{t("Bottle.Config.notes")}</p>{" "}
              </div>
              {product && product.bottle && (
                <div className="notes-wrapper">
                  <InputComponent
                    value={product.bottle.notes || ""}
                    onChange={(value) =>
                      setProduct((oldProduct) => ({
                        ...oldProduct,
                        bottle: { ...oldProduct.bottle!, notes: value },
                      }))
                    }
                    type="text"
                    multiline
                    label={t("Bottle.Config.notesBottle")}
                    disabled={isCustomer}
                  />
                  <InputComponent
                    value={product.notes || ""}
                    onChange={(value) =>
                      setProduct((oldProduct) => ({
                        ...oldProduct,
                        notes: value,
                      }))
                    }
                    type="text"
                    multiline
                    label={t("Bottle.Config.notesProduct")}
                    disabled={isCustomer}
                  />
                </div>
              )}
            </div>
            {user?.role === UserRole.WORKSHOP || <>
            <div>
              <SwitchComponent
                value={includeArchivedDocs}
                onChange={() => {
                  toggleArchivedDocs(!includeArchivedDocs);
                  loadedDocuments.mutate();
                }}
                label={t("Product.includeArchivedDocs")}
              />

              {loadedDocuments.data.length > 0 && (
                <div className="inventory-page--documents-wrapper">
                  <SortableTableComponent
                    columns={t("Product.documentTableHeaders", {
                      returnObjects: true,
                    })}
                    data={generateDocumentData()}
                    selectable
                    onSelectRow={(rows) => {
                      const selectedIds: string[] = loadedDocuments.data
                        .filter((doc) =>
                          rows
                            .map((row) => row.original.id)
                            .includes(doc.id || "")
                        )
                        .flatMap((doc) => doc.id || "");
                      selectedDocumentsRef.current = selectedIds;
                    }}
                  />
                  <ButtonComponent
                    onClick={prepareForProductDocumentDownload}
                    title={t("Product.downloadSelectedDocuments")}
                  />
                </div>
              )}
            </div>
            <div className="inventory-page--past-break-wrapper">
              <SwitchComponent
                value={includePastBreaks}
                onChange={() => {
                  togglePastBreaks(!includePastBreaks);
                  loadedBreaks.mutate();
                }}
                label={t("Product.includePastBreaks")}
              />
            </div>
            {loadedBreaks && loadedBreaks.data.length > 0 && (
              <>
                <SortableTableComponent
                  columns={getAppropriateAreaTable(
                    "blowerBreak",
                    user?.role || UserRole.CUSTOMER
                  )}
                  data={loadedBreaks.data.map((areaBreak) => ({
                    id: areaBreak.id,
                    startDate: getDateString(new Date(areaBreak.startDate)),
                    endDate: getDateString(new Date(areaBreak.endDate)),
                    factoryAreaTitle: getCorrectBreakAssignmentName(areaBreak),
                  }))}
                  onRowClick={(cell) => {
                    setBreakToEdit(
                      loadedBreaks.data.find(
                        (areaBreak) => areaBreak.id === cell.row.original.id
                      )
                    );
                    toggleShowForm(true);
                  }}
                />
              </>
            )}

            {product.type === ProductType.SPRO || (
              <DropDownComponent
                disableAutomaticSelection
                disabled={!selectedCustomer || isCustomer}
                entries={createFactoryAreaDropdown()}
                label={t("Bottle.Config.areaAssignment")}
                onSelect={(selectedValue) =>
                  setProduct({
                    ...product,
                    bottle: {
                      ...product.bottle!,
                      factoryAreaId: selectedValue,
                    },
                  })
                }
                selectedValue={product.bottle?.factoryAreaId || "0"}
              />
            )}
            {product.type !== ProductType.SPRO &&
              (!product.bottle?.factoryAreaId ||
                product.bottle?.factoryAreaId === "0") &&
              product.bottle?.singleAreaBottleConfiguration && (
                <>
                  <DropDownComponent
                    disabled={
                      !selectedCustomer || user?.role !== UserRole.SUPER_ADMIN
                    }
                    entries={createPressureRangeDropDownEntries()}
                    selectedValue={
                      product.bottle?.singleAreaBottleConfiguration!
                        .pressureRange || Festigkeit.FOUR
                    }
                    onSelect={(newValue) =>
                      setProduct({
                        ...product,
                        bottle: {
                          ...product.bottle!,
                          singleAreaBottleConfiguration: {
                            ...product.bottle!.singleAreaBottleConfiguration!,
                            pressureRange: newValue as Festigkeit,
                          },
                        },
                      })
                    }
                    label={t("Area.pressureRange.title")}
                  />
                  {product?.bottle?.config?.maxShots.pressureTen === -1 || (
                    <>
                      <SwitchComponent
                        value={
                          product.bottle?.singleAreaBottleConfiguration!
                            .userConfiguringPerHour || false
                        }
                        label={t(`Area.inHour`)}
                        onChange={(value: boolean) =>
                          setProduct({
                            ...product,
                            bottle: {
                              ...product.bottle!,
                              singleAreaBottleConfiguration: {
                                ...product.bottle!
                                  .singleAreaBottleConfiguration!,
                                userConfiguringPerHour: value,
                              },
                            },
                          })
                        }
                      />

                      <InputComponent
                        disabled={
                          saveButtonIsLoading || !selectedCustomer || isCustomer
                        }
                        required
                        label={
                          product.bottle?.singleAreaBottleConfiguration!
                            .userConfiguringPerHour
                            ? t(`Area.shotsPerHour`)
                            : t(`Area.minutesPerShot`)
                        }
                        value={getCorrectValueForHourOrMinuteInput()}
                        onChange={onChangeHandlerForHourOrMinuteInput}
                        type="number"
                      />
                    </>
                  )}
                </>
              )}

            <InputComponent
              disabled={saveButtonIsLoading || !selectedCustomer || isCustomer}
              required
              label={t("Product.activationDate")}
              value={getProperActivationDate().toISOString().split("T")[0]}
              onChange={(value) =>
                value.length <= 0
                  ? undefined
                  : checkIfGivenDateIsBeforeActivationDate(
                      new Date(Date.parse(value + "T00:00:00.000Z"))
                    )
              }
              onBlur={checkProductValidity}
              bottomErrorLabel={
                !productCheckValidity?.ACTIVATION_DATE_VALID
                  ? t("Product.activationDateError")
                  : ""
              }
              type="date"
            />

            {createBatchMode ||
              product?.bottle?.config?.maxShots.pressureTen === -1 || (
                <div className="inventory-page-history--wrapper">
                  <div className="inventory-page-history--header">
                    <h2>{t("Product.historyHeader")}</h2>
                    <div className="button-wrapper">
                      {historyAvailable ? (
                        <RemoveIcon
                          onClick={() => {
                            toggleHistory(false);
                          }}
                        />
                      ) : (
                        <AddIcon onClick={() => toggleHistory(true)} />
                      )}
                    </div>
                  </div>
                  <div className="inventory-page-history--container-wrapper">
                    {historyAvailable && prepareHistoryShotForRender()}
                    {!productCheckValidity?.HISTORY_SHOT_ORDER && (
                      <p className="inventory-page-history--error">
                        {t("Product.error.HISTORY_SHOT_ORDER")}
                      </p>
                    )}
                    {isCustomer || (
                      <div className="inventory-form--button-wrapper">
                        <ButtonComponent
                          type="button"
                          title={t("Product.addShotCount")}
                          onClick={addHistoryShotCount}
                        />
                        {!product.id || (
                          <ButtonComponent
                            type="button"
                            title={t("Product.recalculateShotCount")}
                            onClick={() => handleSubmit(undefined, true)}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="break-line" />
                  <div className="inventory-form--manual-date-wrapper">
                    <h2>{t("Product.manualDueDateHeader")}</h2>
                    {!product.bottle?.innerDueDate ? (
                      <>
                        {isCustomer || (
                          <ButtonComponent
                            type="button"
                            title={t("Product.addManualInnerDueDates")}
                            onClick={() =>
                              setProduct((oldProduct) => ({
                                ...oldProduct,
                                bottle: {
                                  ...oldProduct.bottle!,
                                  innerDueDate: new Date(),
                                },
                              }))
                            }
                          />
                        )}
                      </>
                    ) : (
                      <div className="inventory-form--manual-date-input">
                        <InputComponent
                          disabled={
                            saveButtonIsLoading ||
                            !selectedCustomer ||
                            isCustomer
                          }
                          onBlur={checkProductValidity}
                          bottomErrorLabel={
                            !productCheckValidity.MANUAL_INNER_VALID
                              ? t("Product.error.MANUAL_INNER_VALID")
                              : ""
                          }
                          required
                          label={t("Product.innerDueDate")}
                          value={
                            (product.bottle.innerDueDate
                              ? new Date(product.bottle.innerDueDate)
                              : new Date()
                            )
                              .toISOString()
                              .split("T")[0]
                          }
                          onChange={(value) =>
                            updatePartialProduct({
                              bottle: {
                                ...product.bottle!,
                                innerDueDate: returnDateIfValid(
                                  value,
                                  product.bottle?.innerDueDate!
                                ),
                              },
                            })
                          }
                          type="date"
                          minDate={
                            product.activationDate
                              ? new Date(product.activationDate)
                                  .toISOString()
                                  .split("T")[0]
                              : ""
                          }
                        />
                        <DeleteOutline
                          onClick={() => {
                            setProduct((oldProduct) => ({
                              ...oldProduct,
                              bottle: {
                                ...oldProduct.bottle!,
                                innerDueDate: undefined,
                              },
                            }));
                          }}
                        />
                      </div>
                    )}
                    {!product.bottle?.festigkeitDueDate ? (
                      <>
                        {isCustomer || (
                          <ButtonComponent
                            type="button"
                            title={t("Product.addManualFestigkeitDueDates")}
                            onClick={() =>
                              setProduct((oldProduct) => ({
                                ...oldProduct,
                                bottle: {
                                  ...oldProduct.bottle!,
                                  festigkeitDueDate: new Date(),
                                },
                              }))
                            }
                          />
                        )}
                      </>
                    ) : (
                      <div className="inventory-form--manual-date-input">
                        <InputComponent
                          disabled={
                            saveButtonIsLoading ||
                            !selectedCustomer ||
                            isCustomer
                          }
                          onBlur={checkProductValidity}
                          bottomErrorLabel={
                            !productCheckValidity.MANUAL_FESTIGKEIT_VALID
                              ? t("Product.error.MANUAL_FESTIGKEIT_VALID")
                              : ""
                          }
                          required
                          label={t("Product.festigkeitDueDate")}
                          value={
                            new Date(product.bottle.festigkeitDueDate)
                              .toISOString()
                              .split("T")[0]
                          }
                          onChange={(value) =>
                            updatePartialProduct({
                              bottle: {
                                ...product.bottle!,
                                festigkeitDueDate: returnDateIfValid(
                                  value,
                                  product.bottle?.festigkeitDueDate!
                                ),
                              },
                            })
                          }
                          type="date"
                          minDate={
                            product.activationDate
                              ? new Date(product.activationDate)
                                  .toISOString()
                                  .split("T")[0]
                              : ""
                          }
                        />
                        <DeleteOutline
                          onClick={() => {
                            setProduct((oldProduct) => ({
                              ...oldProduct,
                              bottle: {
                                ...oldProduct.bottle!,
                                festigkeitDueDate: undefined,
                              },
                            }));
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {user?.role === UserRole.SUPER_ADMIN && (
                    <div className="inventory-form--completed-check-wrapper">
                      <h2>{t("Product.completedCheckHeader")}</h2>
                      <CompletedCheck
                        newDocumentEntry={newInnereDocumentEntry}
                        setNewDocumentEntry={setNewInnereDocumentEntry}
                        dueDateType={DueDateType.INNER}
                        fileToUpload={innerCheckFile}
                        setFileToUpload={setInnerCheckFile}
                        error={!productCheckValidity.INNER_CHECK_VALID}
                      />
                      <CompletedCheck
                        newDocumentEntry={newFestigkeitDocumentEntry}
                        setNewDocumentEntry={setNewFestigkeitDocumentEntry}
                        dueDateType={DueDateType.FESTIGKEIT}
                        fileToUpload={festigkeitCheckFile}
                        setFileToUpload={setFestigkeitCheckFile}
                        error={!productCheckValidity.FESTIGKEIT_CHECK_VALID}
                      />
                    </div>
                  )}
                </div>
              )}
            </>}
            <div className="inventory-form--button-wrapper">
              <ButtonComponent
                title={t("general.button.cancel")}
                disabled={saveButtonIsLoading}
                type="button"
                onClick={() => closeFunction()}
              />

              {product.id && product.type === ProductType.SHOCK_BLOWER && (
                <ButtonComponent
                  title={t("general.button.maintenance")}
                  className={"maintenance-button"}
                  disabled={saveButtonIsLoading}
                  onClick={() =>
                    onLocationChange(NavigationPage.INFO, { product: product })
                  }
                />
              )}
              {isCustomer || (
                <ButtonComponent
                  title={t(
                    `general.button.${product.id ? "update" : "create"}`
                  )}
                  disabled={deleteButtonIsLoading}
                  isLoading={saveButtonIsLoading}
                />
              )}
            </div>
            {product.id && user?.role === UserRole.SUPER_ADMIN && (
              <ButtonComponent
                title={t("general.button.delete")}
                className={"delete-button"}
                disabled={saveButtonIsLoading}
                type="button"
                onClick={() => toggleDeletePopup(true)}
              />
            )}
          </form>
          <PopUpComponent
            fullScreen
            isOpen={popUpIsOpen}
            headerChildren={getPopupHeader()}
            onClose={() => {
              setPopUpIsOpen(false);
              if (isBottleSelection) setSelectedBottleEntries([]);
              setIsBottleSelection(false);
            }}
            onFinish={() => {
              setPopUpIsOpen(false);
              setIsBottleSelection(false);
            }}
          >
            {popUpListEntries ? (
              <ListComponent
                listEntries={popUpListEntries}
                hideIcon
                multipleSelect={isBottleSelection && createBatchMode}
                setSelectedListEntries={
                  isBottleSelection
                    ? (list) => {
                        const setUpSelectedBottles: Bottle[] = [];
                        list.forEach((entry) =>
                          setUpSelectedBottles.push(
                            loadedFilteredBottles.find(
                              (bottle) => bottle.id === entry.id
                            )!
                          )
                        );
                        setSelectedBottleEntries([...setUpSelectedBottles]);
                      }
                    : () => {}
                }
              />
            ) : (
              <></>
            )}
          </PopUpComponent>
          <PopUpComponent
            isOpen={showDocPopup}
            onClose={() => toggleDocPopup(false)}
            fullScreen
          >
            {loadedDocument && (
              <object
                width="100%"
                height="100%"
                data={window.URL.createObjectURL(loadedDocument)}
                type="application/pdf"
                aria-label="Document"
              />
            )}
          </PopUpComponent>
          <PopUpComponent
            isOpen={deletePopupIsOpen}
            onClose={() => {
              toggleDeletePopup(false);
              toggleSaveButtonLoading(false);
            }}
            title={t("general.dialogType.warning")}
          >
            <p className="headerText">{t("Product.deleteProductWarning")}</p>
            <ButtonComponent
              title={t("general.button.delete")}
              className={"delete-button"}
              onClick={handleDelete}
            />
          </PopUpComponent>
        </div>
      )}
    </>
  );
};

export default ProductForm;
